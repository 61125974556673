import * as React from "react";
import { Link } from "gatsby";
import loadable from "@loadable/component";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import "./index.scss";

const ChatApex = loadable(() => import("../ChartApex"));

interface ServicesProps {
  settings?: any;
  products?: any;
  productsSettings?: any;
}

const CompareTable: React.FC<ServicesProps> = ({
  products,
  settings,
  productsSettings,
}) => {
  return (
    <>
      <section className="compare-table nbs">
        <div className="container">
          <h3>{`${products[0]?.title} vs ${products[1]?.title}`}</h3>
          <h2>{productsSettings?.compareBlockTitle}</h2>
          <p className="compare-desc">
            {productsSettings?.compareBlockSubtitle
              .replace("###", products[0]?.title)
              ?.replace("##", products[1]?.title)}
          </p>
        </div>
      </section>
      <section className="compare-table flot">
        <div className="container">
          <div className="compare-wrap">
            <div className="compare-item">
              <ul>
                <li>
                  {products.map((product: any, i: any) => {
                    const image = product?.images
                      ? getImage(product?.images[0]?.src)
                      : false;
                    return (
                      <div
                        className={`compare-item-photo fil${product?.filter}`}
                        key={i}
                      >
                        {image && (
                          <>
                            <Link to={`/${product?.slug}`}>
                              <GatsbyImage image={image} alt="" />
                            </Link>
                            <span>{product?.title}</span>
                          </>
                        )}
                      </div>
                    );
                  })}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="compare-table nts">
        <div className="container">
          <div className="compare-wrap">
            <div className="compare-item scroll-section">
              <ul>
                {settings?.compareTags?.map((e: any, i: any) => {
                  let sameData = "";
                  let activeData = false;
                  products.forEach((product: any, i: any) => {
                    if (product[e?.tag]) {
                      let dataCompare = product[e?.tag];
                      if (sameData) {
                        if (dataCompare !== "N/A") {
                          if (sameData !== dataCompare) {
                            activeData = true;
                          }
                        }
                      } else {
                        sameData = dataCompare;
                      }
                    }
                  });

                  return (
                    <React.Fragment key={i}>
                      <li className="compare-item-head">{e?.text}</li>
                      <li className={activeData ? "active" : ""}>
                        {products.map((product: any, i: any) => {
                          const dataTag = product[e?.tag];
                          if (e?.tag === "where_buy" && dataTag !== "N/A") {
                            return (
                              <div className="compare-item-value" key={i}>
                                {product["brand"] && (
                                  <a
                                    href={product["where_buy"]}
                                    target="_blank"
                                  >
                                    <div className="button">
                                      {product["brand"]}
                                      <LaunchOutlinedIcon />
                                    </div>
                                  </a>
                                )}
                                {!product["brand"] && <div>N/A</div>}
                              </div>
                            );
                          } else {
                            return (
                              <div className="compare-item-value" key={i}>
                                {dataTag ? dataTag : "N/A"}
                              </div>
                            );
                          }
                        })}
                      </li>
                    </React.Fragment>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="product-features-item">
            <div className="product-values-head">
              <img
                src={`${process.env.PUBLIC_URL}/img/icons/electric70.svg`}
                alt=""
              />
              <h2 className="item-title">
                {productsSettings?.comparePerformanceCompare}
              </h2>
            </div>
            <ChatApex
              type="perf"
              productsSettings={{
                ...productsSettings,
                chatPerfTop: productsSettings?.chatPerfTopCompare,
              }}
              products={products}
              curent={products[0]?.slug}
              second={products[1]?.slug}
            />
            <ChatApex
              type="battery"
              productsSettings={{
                ...productsSettings,
                chatBatteryTop: productsSettings?.chatPerfTopCompareBattery,
              }}
              products={products}
              curent={products[0]?.slug}
              second={products[1]?.slug}
            />
          </div>
          <div className="product-features-item">
            <div className="product-values-head">
              <img
                src={`${process.env.PUBLIC_URL}/img/icons/electric8.svg`}
                alt=""
              />
              <h2 className="item-title">
                {productsSettings?.comparePriceHistoryCompare}
              </h2>
            </div>

            <ChatApex
              type="price"
              productsSettings={{
                ...productsSettings,
                chatPriceTop: productsSettings?.chatPerfTopComparePrice,
              }}
              products={products}
              curent={products[0]?.slug}
              second={products[1]?.slug}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default CompareTable;
